<template>
  <div class="checkout-container" v-if="loaded">
    <div class="items-container">
      <h2>Register</h2>

      <div class="divisions">
        <p class="section-title">Choose division</p>
        <slide-up-down :active="chosenDivision == null" :duration="300">
          <ul class="items-list">
            <li
              class="division"
              v-for="division in eventData.tour.divisions"
              :key="division.id"
              @click="selectDivision(division)"
            >
              <p>
                <strong>{{ division.name }}</strong>
              </p>
              <p v-if="division.registrationFee">
                {{ division.registrationFee.value }}
                {{ division.registrationFee.currency }}
              </p>
              <p v-else>0 SEK</p>
            </li>
          </ul>
        </slide-up-down>
        <slide-up-down :active="chosenDivision !== null" :duration="300">
          <ul class="items-list" v-if="chosenDivision">
            <li
              class="division selected"
              @click="deSelectDivision(chosenDivision)"
            >
              <p>
                <strong>{{ chosenDivision.name }}</strong>
              </p>
              <p class="change">Change</p>
            </li>
          </ul>
          <div
            class="tour-pass"
            v-if="chosenDivision && chosenDivision.tourPassFee"
          >
            <p class="section-title">Add tour pass (optional)</p>

            <ul class="items-list">
              <li
                class="division"
                :class="{
                  selected: additionalItems.some(
                    (item) => item.id == chosenDivision.id
                  ),
                }"
                @click="
                  addItemToCart(
                    chosenDivision.id,
                    'Tour pass',
                    chosenDivision.tourPassFee.value,
                    chosenDivision.registrationFee.currency
                  )
                "
              >
                <p>
                  <strong>{{ chosenDivision.name }}</strong>
                </p>
                <p>{{ chosenDivision.tourPassFee.value }} {{ currency }}</p>
              </li>
            </ul>
            <p class="sub-text">
              Purchase all <i>{{ eventData.tour.name }}</i> events by adding a
              tour pass. <strong>Note:</strong> You need to register for any
              upcoming events you wish to play when the event registration opens
              but you will not need to pay again.
            </p>
          </div>
        </slide-up-down>
      </div>
      <div class="reg-note" v-if="eventData.tour.allowRegistrationMessage">
        <div
          class="reg-message-header"
          @click="showRegMessage = !showRegMessage"
        >
          <p class="section-title">Add a note</p>
          <Chevron2 :direction="showRegMessage ? 'up' : 'down'" />
        </div>
        <slide-up-down :active="showRegMessage" :duration="300">
          <textarea
            class="reg-note-area"
            rows="3"
            maxlength="250"
            v-model="registrationMessage"
          ></textarea>
        </slide-up-down>
      </div>
    </div>
    <div class="summary-container">
      <p class="section-title">Summary</p>
      <div class="line-items">
        <div class="division-item" v-if="chosenDivision">
          <p>
            <strong>{{ chosenDivision.name }}</strong>
          </p>
          <p>{{ selectedDivisionCost }} {{ currency }}</p>
        </div>

        <div
          class="additional-item"
          v-for="item in additionalItems"
          :key="item.id"
        >
          <p>
            <strong>{{ item.name }}</strong>
          </p>
          <p>{{ item.cost }} {{ item.currency }}</p>
        </div>
      </div>

      <div class="total">
        <p><strong>Total</strong></p>
        <p>
          <strong>{{ cartTotal }} {{ currency }}</strong>
        </p>
      </div>

      <div
        class="payment-info"
        v-if="
          eventData.paymentInfo &&
          cartTotal > 0 &&
          eventData.tour.paymentType == 'MANUAL'
        "
      >
        <p><strong>Payment info:</strong></p>
        <p>{{ eventData.paymentInfo }}</p>
      </div>
      <div
        class="card-widget"
        v-show="eventData.tour.paymentType == 'MANAGED' && cartTotal > 0"
      >
        <p>Pay with card</p>
        <div class="card-number">
          <label for="card-element">Card number</label>
          <div ref="cardNumber">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <div class="stripe-error">
            <span ref="cardError" />
          </div>
        </div>
        <div class="details-container">
          <div class="expires">
            <label for="card-element">Expiry</label>
            <div ref="cardExpiry">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <div class="stripe-error">
              <span ref="expiryError" />
            </div>
          </div>

          <div class="cvc">
            <label for="card-element">CVC</label>
            <div ref="cardCvc">
              <!-- A Stripe Element will be inserted here. -->
            </div>
            <div class="stripe-error">
              <span ref="cvcError" />
            </div>
          </div>
        </div>
        <div
          ref="apiErrors"
          class="stripe-api-errors"
          v-if="stripe.errorMessage"
        >
          {{ stripe.errorMessage }}
        </div>
      </div>
      <div class="agreements" v-if="firebaseData">
        <div class="waiver" v-if="firebaseData.pdgaCovidWaiverEnabled">
          <DefaultCheckBox :disabled="false" v-model="pdgaWaiverChecked" />
          <p>
            I have read and agree to the
            <a
              :href="'/event/' + $route.params.eventId + '/pdgawaiver'"
              target="_blank"
              >PDGA Covid-19 Waiver</a
            >
          </p>
        </div>
        <div class="custom-agreement" v-if="firebaseData.customAgreements">
          <DefaultCheckBox :disabled="false" v-model="customAgreementChecked" />
          <p>
            I have read and agree to the
            <a :href="firebaseData.customAgreementsURL" target="_blank">{{
              firebaseData.customAgreementsName
            }}</a>
          </p>
        </div>
      </div>
      <div class="confirmation">
        <button class="payment-button" v-if="payButtonClicked">
          <div class="preloader">
            <div class="circ1" />
            <div class="circ2" />
            <div class="circ3" />
            <div class="circ4" />
          </div>
        </button>
        <StandardSolidButton
          v-else
          :title="useStripe ? 'Pay now to register' : 'Register'"
          :fluid="false"
          :disabled="!registrationValid"
          @click="
            if (registrationValid) {
              processRegistration();
            }
          "
        ></StandardSolidButton>
      </div>
    </div>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="stagesinfo"
      hide-close-button
    >
      <h3 v-if="_isUserEligibleToRegister.activeStagesCount == 0">
        All stages are currently closed
      </h3>
      <h3 v-else-if="_isUserEligibleToRegister.firstElegibleStage !== null">
        You are not eligible to register for this division yet
      </h3>
      <h3 v-else-if="_isUserEligibleToRegister.firstElegibleStage == null">
        You are not eligible to register for this division
      </h3>
      <h3 v-else>You are not eligible to register for this division</h3>

      <p
        v-if="_isUserEligibleToRegister.firstElegibleStage == null"
        class="description"
      >
        This division uses registration stages. Based on the current criteria
        set for this division you are not eligible to register.
      </p>
      <p
        v-else-if="_isUserEligibleToRegister.firstElegibleStage != null"
        class="description"
      >
        This division uses registration stages. You are eligible to register
        once
        <b
          >stage
          {{ _isUserEligibleToRegister.firstElegibleStage.stageNumber }}</b
        >
        is active, which is scheduled to open on:
        <b>{{
          formattedStageDate(_isUserEligibleToRegister.firstElegibleStage.date)
        }}</b>
      </p>

      <div class="button">
        <StandardSolidButton
          title="Okay, got it"
          :fluid="false"
          @click="closeStageModal()"
        />
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import moment from "moment";
import DefaultCheckBox from "@/components/UIElements/DefaultCheckBox";
import db from "@/functions/firebaseInit";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import SlideUpDown from "vue-slide-up-down";
import Chevron2 from "@/assets/icons/Chevron2";

export default {
  name: "Checkout",
  components: {
    DefaultCheckBox,
    StandardSolidButton,
    SlideUpDown,
    Chevron2,
  },
  data() {
    return {
      loaded: false,
      eventData: null,
      userInfo: null,
      payButtonClicked: false,
      customAgreementChecked: false,
      pdgaWaiverChecked: false,
      firebaseData: null,
      chosenDivision: null,
      registrationMessage: null,
      showRegMessage: false,
      additionalItems: [],
      stripe: {
        stripeFunctions: null,
        errorMessage: null,
        elements: {},
        card: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        cardFilled: false,
        expiryFilled: false,
        cvcFilled: false,
      },
    };
  },
  watch: {
    chosenDivision: function (newVal, oldVal) {
      if (this._isUserEligibleToRegister.eligible == false && newVal != null) {
        this.$refs.stagesinfo.open();
      }
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    useStripe() {
      return this.eventData.tour.paymentType == "MANAGED" && this.cartTotal > 0;
    },
    cartTotal() {
      let cartTotal = 0;

      cartTotal = cartTotal + this.selectedDivisionCost;

      this.additionalItems.forEach((item) => {
        cartTotal = cartTotal + item.cost;
      });
      return cartTotal;
    },
    tourPassSelected() {
      return this.additionalItems.some((item) => item.name == "Tour pass");
    },
    selectedDivisionCost() {
      let cost = 0;

      if (this.chosenDivision?.registrationFee) {
        if (this.tourPassSelected == false) {
          cost = this.chosenDivision.registrationFee.value;
        }
      }

      return cost;
    },
    _isUserEligibleToRegister() {
      let info = {
        eligible: true,
        firstElegibleStage: null,
        activeStagesCount: 0,
      };
      let eligibilityPerStage = [];

      if (this.chosenDivision == null) {
        info.eligible = false;
      } else if (this.eventData.registrationStages.length == 0) {
        info.eligible = true;
      } else if (
        this.eventData.registrationStages.filter(
          (stage) => stage.division.id == this.chosenDivision.id
        ).length == 0
      ) {
        info.eligible = true;
      } else {
        this.eventData.registrationStages.forEach((stage, stageIndex) => {
          let PDGA_NUMBER_ELEGIBLE = true;
          let MAX_PDGA_RATING_ELEGIBLE = true;
          let MIN_PDGA_RATING_ELEGIBLE = true;

          if (stage.active) {
            info.activeStagesCount++;
          }

          if (stage.division.id == this.chosenDivision.id) {
            stage.criterias.forEach((criteria) => {
              if (
                criteria.type == "OPEN_FOR_ALL" &&
                stage.criterias.length == 1 &&
                stage.active
              ) {
                eligibilityPerStage.push("yes");
              }
              if (criteria.type == "PDGA_NUMBER") {
                if (this.userInfo.pdgaNumber == null) {
                  PDGA_NUMBER_ELEGIBLE = false;
                }
              }
              if (criteria.type == "MAX_PDGA_RATING") {
                if (
                  this.userInfo.pdgaNumber == null ||
                  this.userInfo.pdgaRating > parseInt(criteria.value)
                ) {
                  MAX_PDGA_RATING_ELEGIBLE = false;
                }
              }
              if (criteria.type == "MIN_PDGA_RATING") {
                if (
                  this.userInfo.pdgaNumber == null ||
                  this.userInfo.pdgaRating < parseInt(criteria.value)
                ) {
                  MIN_PDGA_RATING_ELEGIBLE = false;
                }
              }
            });

            if (
              !PDGA_NUMBER_ELEGIBLE ||
              !MAX_PDGA_RATING_ELEGIBLE ||
              !MIN_PDGA_RATING_ELEGIBLE
            ) {
              if (stage.active) {
                eligibilityPerStage.push("no");
              }
            } else {
              if (stage.active) {
                eligibilityPerStage.push("yes");
              }

              if (info.firstElegibleStage == null) {
                info.firstElegibleStage = stage;
              }
            }
          }
        });
        if (!eligibilityPerStage.includes("yes")) {
          info.eligible = false;
        }
      }

      return info;
    },
    registrationValid() {
      if (this._isUserEligibleToRegister.eligible == false) {
        return false;
      }

      if (this.firebaseData) {
        if (
          this.firebaseData.customAgreements &&
          this.customAgreementChecked == false
        ) {
          return false;
        }
        if (
          this.firebaseData.pdgaCovidWaiverEnabled &&
          this.pdgaWaiverChecked == false
        ) {
          return false;
        }
      }

      if (this.eventData.tour.paymentType == "MANAGED" && this.cartTotal > 0) {
        if (this.stripe.cardFilled == false) return false;
        if (this.stripe.expiryFilled == false) return false;
        if (this.stripe.cvcFilled == false) return false;
      }

      return true;
    },
    currency() {
      return this.chosenDivision?.registrationFee?.currency
        ? this.chosenDivision?.registrationFee.currency
        : "SEK";
    },
  },
  methods: {
    closeStageModal() {
      this.$refs.stagesinfo.close();
      this.chosenDivision = null;
    },
    processRegistration() {
      if (this.useStripe) {
        this.pay();
      } else {
        this.registerUser();
      }
    },
    async registerUser() {
      let groupMutation = "";
      let messageMutation = "";

      if (this.$router.currentRoute.params.groupId) {
        groupMutation = `, groupId: "${this.$router.currentRoute.params.groupId}"`;
      }

      if (this.registrationMessage) {
        messageMutation = `, message: "${this.registrationMessage}"`;
      }

      try {
        let registration = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              RegisterToEvent(
                eventId:"${this.$router.currentRoute.params.eventId}",
                divisionId:"${this.chosenDivision.id}"
                ${messageMutation}
                ${groupMutation}
              ){
                ... on Registration{
                  id
                  status
                }
              }
            }
            `,
          },
        });

        if (registration.data.data.RegisterToEvent) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are now registered",
            type: "success",
          });
          this.$store.dispatch("updateUserEventInformation");
          this.toEvent();
        } else {
          let errorMessage = "Unable to complete registration";
          let errorCode = null;

          if (registration.data.errors) {
            errorCode = registration.data.errors[0].errorCode;
          }

          if (errorCode == "REGISTRATION_ALREADY_EXISTS")
            errorMessage = "You are already registered to this event";
          if (errorCode == "USER_ALREADY_A_PLAYER")
            errorMessage = "You are already registered to this event";
          if (errorCode == "NOT_PASSING_REGISTRATION_STAGE")
            errorMessage = "Illegible for this registration stage";

          this.$store.dispatch("showMessage", {
            show: true,
            message: errorMessage,
            type: "error",
          });
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    selectDivision(division) {
      this.chosenDivision = division;
    },
    deSelectDivision(division) {
      this.chosenDivision = null;
      this.removeItemFromCart(division.id);
    },
    removeItemFromCart(id) {
      this.additionalItems = this.additionalItems.filter(
        (item) => item.id != id
      );
    },
    addItemToCart(id, name, cost, currency) {
      if (this.additionalItems.some((item) => item.id === id) == false) {
        this.additionalItems.push({ id, name, cost, currency });
      } else {
        this.removeItemFromCart(id);
      }
    },
    arrangeStageNumbers() {
      this.eventData.tour.divisions.forEach((division, divisionIndex) => {
        var stageNumber = 1;
        this.eventData.registrationStages.forEach((stage, stageIndex) => {
          if (division.id == stage.division.id) {
            stage.stageNumber = stageNumber;
            stageNumber++;
          }
        });
      });
    },
    formattedStageDate() {
      return moment(
        this._isUserEligibleToRegister.firstElegibleStage.date
      ).format("ddd, DD, MMM YYYY [at] HH:mm");
    },
    missingInformation() {
      this.$store.dispatch("showMessage", {
        show: true,
        message: "Please confirm you agree to the terms",
        type: "error",
      });
    },
    async loadFirebaseEventData() {
      await db
        .collection("events")
        .doc(this.$router.currentRoute.params.eventId)
        .get()
        .then((data) => {
          if (data.exists) {
            this.firebaseData = data.data();
          }
        });
    },
    async pay() {
      const paymentType = this.tourPassSelected ? "TOUR" : "EVENT";
      let messageMutation = "";

      let groupMutation = this.$route.params.groupId
        ? `, groupId: "${this.$route.params.groupId}"`
        : "";

      if (this.registrationMessage) {
        messageMutation = `, message: "${this.registrationMessage}"`;
      }

      this.payButtonClicked = true;
      this.stripe.errorMessage = "";
      let paymentData = await this.stripe.stripeFunctions.createPaymentMethod(
        "card",
        this.stripe.elements.cardNumber
      );

      if (paymentData.error) {
        this.payButtonClicked = false;
      } else {
        let registrationRequest = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation RegisterToEvent($RegisterToEvent: CreateRegistrationPaymentInput){
                RegisterToEvent(
                  eventId:"${this.$route.params.eventId}",
                  divisionId:"${this.chosenDivision.id}",
                  payment:$RegisterToEvent
                  ${messageMutation}
                  ${groupMutation}
                ){
                  ... on Registration{
                    id
                    status
                  }
                  ... on PaymentIntent{
                    id
                    clientSecret
                  }
                }
              }
            `,
            variables: {
              RegisterToEvent: {
                type: paymentType,
                token: paymentData.paymentMethod.id,
              },
            },
          },
        });

        if (registrationRequest.data.errors) {
          if (
            registrationRequest.data.errors[0].errorCode ==
            "USER_HAS_TOUR_PASS_FOR_OTHER_DIVISION"
          ) {
            this.payButtonClicked = false;
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Tourpass for other division",
              type: "error",
            });
          } else {
            this.showStripeError(registrationRequest.data.errors[0].errorCode);
          }
        } else if (registrationRequest.data.data.RegisterToEvent.status) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are now registered",
            type: "success",
            style: "avocado",
          });
          this.$store.dispatch("updateUserEventInformation");
          this.toEvent();
        } else if (registrationRequest.data.data.RegisterToEvent.clientSecret) {
          this.authenticatePayment(
            registrationRequest.data.data.RegisterToEvent
          );
        }
      }
    },
    showStripeError(errorCode) {
      this.payButtonClicked = false;

      if (errorCode == "INSUFFICIENT_FUNDS") {
        this.stripe.errorMessage = "Insufficient funds on card";
      } else if (errorCode == "STRIPE_CARD_DECLINED") {
        this.stripe.errorMessage = "Card declined";
      }
      if (errorCode == "STRIPE_CARD_ERROR") {
        this.stripe.errorMessage = "Generic card error";
      }
      if (errorCode == "STRIPE_UNKNOWN_ERROR") {
        this.stripe.errorMessage = "Service temporarily unavailable";
      }
    },
    async authenticatePayment(paymentData) {
      let handleCardAction = await this.stripe.stripeFunctions.handleCardAction(
        paymentData.clientSecret
      );

      if (handleCardAction.error) {
        this.payButtonClicked = false;
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Payment validation failed!",
          type: "error",
          style: "midnight",
        });
      } else {
        let confirmEventRegistrationPayment = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
                mutation{
                  ConfirmEventRegistrationPayment(paymentIntentId:"${paymentData.id}",stripePaymentIntentId:"${handleCardAction.paymentIntent.id}"){
                    id
                  }
                }
                `,
          },
        });
        if (confirmEventRegistrationPayment.data.errors) {
          this.showStripeError(
            confirmEventRegistrationPayment.data.errors[0].errorCode
          );
        } else {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "You are now registered",
            type: "success",
            style: "avocado",
          });

          this.$store.dispatch("updateUserEventInformation");
          this.toEvent();
        }
      }
    },
    initiateStripeElements() {
      this.stripe.stripeFunctions = Stripe(
        process.env.VUE_APP_STRIPE_PAYMENT_TOKEN,
        {
          stripeAccount: this.eventData.stripeAccountId,
        }
      );

      let options = {
        locale: "en",
      };

      let elements = this.stripe.stripeFunctions.elements(options);
      // Create an instance of the card Element.
      this.stripe.elements.cardNumber = elements.create("cardNumber");
      this.stripe.elements.cardExpiry = elements.create("cardExpiry");
      this.stripe.elements.cardCvc = elements.create("cardCvc");

      // Add an instance of the card Element into the `card-element` <div>.
      this.stripe.elements.cardNumber.mount(this.$refs.cardNumber);
      this.stripe.elements.cardExpiry.mount(this.$refs.cardExpiry);
      this.stripe.elements.cardCvc.mount(this.$refs.cardCvc);

      // Handle real-time validation errors from the card Element.
      this.stripe.elements.cardNumber.addEventListener("change", (event) => {
        event.error
          ? (this.$refs.cardError.textContent = event.error.message)
          : (this.$refs.cardError.textContent = "");

        event.complete
          ? (this.stripe.cardFilled = true)
          : (this.stripe.cardFilled = false);
      });

      // Handle real-time validation errors from the card Element.
      this.stripe.elements.cardCvc.addEventListener("change", (event) => {
        event.error
          ? (this.$refs.cvcError.textContent = event.error.message)
          : (this.$refs.cvcError.textContent = "");
        event.complete
          ? (this.stripe.expiryFilled = true)
          : (this.stripe.expiryFilled = false);
      });

      // Handle real-time validation errors from the card Element.
      this.stripe.elements.cardExpiry.addEventListener("change", (event) => {
        event.error
          ? (this.$refs.expiryError.textContent = event.error.message)
          : (this.$refs.expiryError.textContent = "");
        event.complete
          ? (this.stripe.cvcFilled = true)
          : (this.stripe.cvcFilled = false);
      });
    },
    toEvent() {
      this.$router.push({
        name: "public-event-players",
        params: { eventId: this.$route.params.eventId },
      });
    },
    closeModal() {
      this.showModal = false;
    },
    loadData() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me{
              profile{
                pdgaRating
                pdgaNumber
              }
            }
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              name
              stripeAccountId
              status
              location
              paymentInfo
              isRegistrationOpen
              city{
                id
                name
                country
              }
              rounds{
                pools{
                  date
                }
              }
              registrationStages{
                id
                date
                active
                criterias{
                  id
                  type
                  value
                }
                division{
                  id
                  name
                }
              }
              tour{
                id
                name
                paymentType
                allowRegistrationMessage
                divisions{
                  id
                  type
                  name
                  tourPassFee{
                    value
                    formatted
                    currency
                  }
                  registrationFee{
                    value
                    formatted
                    currency
                  }
                }
                events{
                  id
                  name
                  status
                  rounds{
                    id
                    pools{
                      id
                      status
                      date
                    }
                  }
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.event.isRegistrationOpen) {
            this.eventData = result.data.data.event;
            this.eventData.registrationStages = orderBy(
              this.eventData.registrationStages,
              "date",
              "asc"
            );
            this.userInfo = result.data.data.me.profile;
            this.arrangeStageNumbers();
            this.loaded = true;

            if (this.eventData.tour.paymentType == "MANAGED") {
              this.$nextTick(() => {
                this.initiateStripeElements();
              });
            }
          } else {
            this.$router.push({ name: "public-event-results" });
          }
        })

        .catch(() => {});
    },
  },
  created() {},
  async beforeMount() {
    this.loadFirebaseEventData();

    if (this.isUserLoggedIn) {
      try {
        await this.$loadScript("https://js.stripe.com/v3/");
      } catch (err) {
        console.log(err);
      }

      this.loadData();
    } else {
      this.$store.dispatch("setReturnToRouteData", this.$route);
      this.$router.push({
        name: "login",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.reg-note {
  padding-top: 18px;
  border-top: 1px solid $fog;
  margin-top: 32px;

  .reg-message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .reg-note-area {
    border-radius: 6px;
    width: 100%;
    resize: vertical;
    max-height: 300px;
    padding: 15px;
    border: 1px solid $blizzard;

    &:hover,
    &:focus {
      border: 1px solid $midnight;
    }
  }
}

h2 {
  font-size: 24px;
  @include Gilroy-Bold;
  margin: 32px 0 0 0;
  padding: 0 0 0 6px;
}
p {
  font-size: 14px;
  margin: 0;

  &.section-title {
    padding-left: 6px;
    font-size: 16px;
    margin-bottom: 12px;
  }
}
strong {
  @include Gilroy-Bold;
}
i {
  @include Gilroy-LightItalic;
}
.stripe-api-errors {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  color: $dusk;
  @include Gilroy-Bold;
  margin-top: 24px;
}
.payment-button {
  width: 100%;
  color: white;
  background: $twilight;
  height: 48px;
  @include Gilroy-Bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:hover {
    background: $twilightshade;
    cursor: default;
  }
}
.preloader {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 10px;
  position: relative;
}

.preloader > div {
  background-color: white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
  animation: stretchdelay 0.7s infinite ease-in-out;
}
.preloader .circ1 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.preloader .circ2 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.preloader .circ3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.preloader .circ4 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.preloader .circ5 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: translateY(-10px);
  }
  20% {
    -webkit-transform: translateY(-20px);
  }
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
}
.payment-info {
  padding: 0 6px;
  strong {
    color: $strom;
    margin-bottom: 10px;
  }
  margin: 24px 0 0 0;
}
.card-widget {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;

  label {
    padding-left: 6px;
  }

  p {
    font-size: 16px;
    padding-left: 6px;
  }

  .stripe-error {
    color: $dusk;
    font-size: 14px;
    margin-top: 8px;
  }

  .details-container {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .expires,
  .cvc {
    width: 45%;
    label {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
  .card-number {
    width: 100%;
    margin-top: 12px;

    label {
      margin-bottom: 5px;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
  .StripeElement {
    background-color: white;
    height: 50px;
    padding: 16px 15px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid $mouse;
    -webkit-transition: box-shadow 150ms ease;
    transition: all 150ms ease;

    div {
      width: 100%;
    }
  }

  .StripeElement--focus {
    border: 1px solid $blizzard;
  }

  .StripeElement--invalid {
    border-color: $dusk;
    color: $dusk;
  }
}
.confirmation {
  margin-top: 42px;
}

.registration-message {
  margin-top: 24px;
}

.agreements {
  margin-top: 32px;
  .waiver,
  .custom-agreement {
    display: flex;
    align-items: center;
    margin-top: 12px;
    p {
      font-size: 12px;
    }
    a {
      color: $ocean;
      &:hover {
        text-decoration: underline;
      }
    }
    .default-checkbox {
      margin-right: 8px;
    }
  }
}
.summary-container {
  margin-top: 24px;
  padding-bottom: 48px;

  .line-items {
    margin-top: 12px;
    p {
      font-size: 18px;
    }
  }

  .division-item,
  .additional-item {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-bottom: 1px solid $fog;
    padding: 0 6px 12px 6px;
    margin-top: 12px;
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-bottom: 1px solid $fog;
    padding: 0 6px 12px 6px;
    margin-top: 12px;
    p {
      font-size: 18px;
      color: $ocean;
    }
  }
}

.tour-pass {
  .sub-text {
    margin: 24px 0 12px 0;
  }
}

.items-list {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 1px;
  transition: all ease 0.3s;
  li {
    cursor: pointer;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    box-shadow: 0 0 0 1px $blizzard;
    border-radius: 8px;
    padding: 0 12px;
    transition: all ease 0.3s;
    p {
      font-size: 16px;
      margin: 0;
    }
    strong {
      font-size: 18px;
    }

    &.selected {
      background: #e5f8f8;
      box-shadow: 0 0 0 2px $ocean;
    }

    &:hover {
      box-shadow: 0 0 0 2px $ocean;
    }
  }

  .change {
    color: $ocean;
    font-size: 12px;
    @include Gilroy-Bold;
  }
}

.divisions {
  margin-top: 24px;
}
.checkout-container {
  margin-top: 24px;
  padding: 0 12px;
  border-top: 1px solid $fog;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  h2 {
    padding: 0;
    font-size: 32px;
  }
  p {
    &.section-title {
      padding: 0;
    }
  }

  .payment-info {
    padding: 0;
  }

  .items-list {
    li {
      p {
        font-size: 18px;
      }
      strong {
        font-size: 18px;
      }
    }

    .change {
      font-size: 18px;
      @include Gilroy-Regular;
    }
  }

  .registration-message {
    margin-top: 24px;
    .default-inputfield {
      width: 100%;
    }
  }

  .checkout-container {
    display: flex;
    padding: 0;

    .items-container {
      width: 45%;
      padding-right: 10%;
    }

    .summary-container {
      width: 55%;
      padding: 0 15% 48px 5%;
      background: $snow;
      margin-top: 0;
      padding-top: 99px;

      .total {
        padding: 0 0 12px 0;
      }

      .division-item,
      .additional-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .card-widget {
    p {
      padding-left: 0;
    }
    label {
      padding-left: 0px;
    }
  }
}
</style>

